import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

const SpinnerWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
  transition: all .7s;
`

interface SpinnerProps {
  inProp: boolean
}

const Spinner = (props: SpinnerProps) => {
  return (
    <AnimatePresence>
      {props.inProp && (
        <SpinnerWrapper 
          key="spinner"
          data-testid="spinner"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: .5 }}>
          <div className="spinner--wv-star">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </SpinnerWrapper>
      )}
    </AnimatePresence>
  )
}

export default Spinner
