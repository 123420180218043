import { useEffect, useContext, useState } from 'react'
import StateContext from '../../helpers/stateContext'
import { getEventByKeyword } from '../../helpers/eventApi'
import styled from 'styled-components'
import SponsorshipCount from './SponsorshipCount'
import EventInfo from './EventInfo'
import GoalText from './GoalText'

const EventSummaryWrapper = styled.section`
  padding: 1.6rem;

  @media screen and (max-width: 748px) {
    padding: 0;
  }
`

const EventDataWrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 6fr;
  grid-gap: 2.4rem;

  .grayBox {
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: rgba(204, 204, 204, 0.5) 0px 3px 5px 0px;
  }

  .two-col {
    display: flex;
  }

  .eventContainer {
    padding: 2.2rem 0rem 3.2rem 3.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: flex-start;
    flex: 1 1 0;

    .hr-line {
      max-width: 200px;
      border-bottom: 1px solid #ff6a00;
      margin: 20px 20px 20px 0;
    }
  }
  .mapContainer {
    display: flex;
    flex: 1 1 0;
    align-items: center;
    justify-content: center;
    padding-right: 32px;

    .overlay {
      display: grid;
      grid-template: 1fr / 1fr;
    }

    .map {
      width: 100%;
      max-height: 187px;
      grid-area: 1/1;
    }

    .mapPin {
      z-index: 2;
    }

    .baseMap {
      filter: brightness(0.6);
    }
  }

  .padding {
    padding: 2rem 3.2rem 0;
  }

  .chosenSpon {
    display: flex;
    flex-direction: column;
  }

  .left {
    text-align: left;
    margin: 0;
  }

  .chosenImg {
    width: 100%;
    height: 120px;
    background-position: center;
    object-fit: cover;
    position: relative;
    border-radius: 8px 8px 0px 0px;
  }

    h2 {
      font-size: 2.4rem;
      line-height: 2.9rem;
      text-align: center;
      margin: 0 auto 1.6rem;
    }
  }

  @media screen and (max-width: 748px) {
    grid-template-columns: 1fr;

    .two-col {
      margin-bottom: 3.2rem;
    }

    h2 {
      font-size: 1.6rem;
      line-height: 1.9rem;
    }

    .eventContainer h2 {
      font-size: 2rem;
      line-height: 2.4rem;
    }

    .padding {
      padding: 2rem 2.4rem 0;
    }

    .eventContainer {
      padding: 2.5rem 2.4rem 1.6rem;
    }

    .mapContainer {
      padding: 16px;
    }
  }

  @media screen and (max-width: 495px) {
    .two-col, .eventContainer, .mapContainer {
      flex-direction: column;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex: unset;
    }

    .mapContainer {
      padding: 1rem 2rem 2rem 2rem;
    }
  }
`

const EventSummary = () => {
  const { state, dispatch } = useContext(StateContext) as any
  const { queryParams, eventData, totalSponsorships } = state
  const { Country, SignupEndDate, RevealDate } = eventData
  const { keyword } = queryParams
  const [imgPath, setImgPath] = useState('https://wvusstatic.com/2021/landing-pages/pastor-dashboard/pre-goal.jpg');
  const countryLowerCase = Country && Country.split(' ').join('-').toLowerCase();

  useEffect(() => {
    if (keyword) {
      getEventByKeyword(keyword)
        .then((respData) => {
          dispatch({ type: 'updateEventData', payload: respData })
        })
    }

    if (totalSponsorships >= parseInt(queryParams.goal)) {
      setImgPath('https://wvusstatic.com/2021/landing-pages/pastor-dashboard/post-goal.jpg');
    }
  }, [keyword, totalSponsorships])

  return (
    <EventSummaryWrapper>
      <EventDataWrapper>
        <div className="grayBox chosenSpon" id="animationDiv">
          <img
            className="chosenImg"
            src={imgPath}
            alt="Child chosing Chosen Sponsor"
          />
          <div className="padding">
            <GoalText isGoalMet={totalSponsorships >= parseInt(queryParams.goal)} />
            <SponsorshipCount goal={queryParams.goal} totalSponsorships={totalSponsorships} />
          </div>
        </div>

        <div className="grayBox two-col">
          <div className="eventContainer">
            <h2 className="left">Event Information:</h2>
            <div className="hr-line" />
            <EventInfo country={Country} signupEndDate={SignupEndDate} revealDate={RevealDate} />
          </div>
          <div className="mapContainer">
            <div className="overlay">
              <img src={Country && `https://wvusstatic.com/cn/countries/maps/${countryLowerCase}.png`} alt={`World map with ${Country} location pin`} className="map mapPin" />
              <img src="https://wvusstatic.com/cn/countries/maps/base-map.png" alt="World map" className="map baseMap" />
            </div>
          </div>
        </div>
      </EventDataWrapper>
    </EventSummaryWrapper>
  )
}

export default EventSummary
