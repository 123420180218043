import styled from 'styled-components'
import { useContext } from 'react'
import StateContext from '../helpers/stateContext'

const FooterWrapper = styled.footer`
  margin-top: 3.2rem;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 1.6rem 3.2rem 1.6rem;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;

  .keyword {
    margin: 0;
  }

  .flex {
    display: flex;
  }

  .smaller, .divider {
    font-size: 1.2rem;
    margin: 0 0 0 1rem;
  }

  @media screen and (max-width: 495px) {
    .flex {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
    .divider {
      display: none;
    }
  }
`

let today = new Date()

const Footer = () => {

  const { state } = useContext(StateContext) as any
  const { eventData } = state

  return (

    <FooterWrapper>
      {eventData ? (
        <p className="keyword">Keyword: <strong>{state.queryParams.keyword}</strong></p>
      ) : ''}
      <div className="flex">
        {state.eventIds.length > 0 && (
          <>
            <p className="smaller">
              CRM Event ID: {state.eventIds.join(', ')}
            </p>
            <p className="divider"> | </p>
          </>
        )}
        <p className="smaller"> &copy; {today.getFullYear()} World Vision, Inc. All rights reserved.</p>
      </div>
    </FooterWrapper>
  )
}

export default Footer;