import { useContext } from 'react'
import styled from 'styled-components'
import logo from '../svg/logo.svg'
import stateContext from '../helpers/stateContext'
import EventSummary from './eventSummary/EventSummary'


const HeaderContainer = styled.header`

  padding: 0 0 2.5rem;
  background: white;

  .wrapper {
    max-width: 1200px;
    margin: auto;
  }

  h1 {
    font-size: 3rem;
    line-height: 3.6rem;
  }

  @media screen and (max-width: 748px) {
    padding: 2rem 1.6rem 0;
  }
`
const TitleContainer = styled.div`

  display: flex;
  justify-content: space-between;
  padding: 1.6rem;

  .wv-logo {
    max-width: 257px;
    width: 100%;
  }
  
  @media screen and (max-width: 748px) {
    flex-direction: column-reverse;
    justify-content: center;
    text-align: center;
    padding: 0 1.6rem 1.6rem;

    h1 {
      font-size: 2rem;
      line-height: 2.8rem;
      margin: 1.6rem auto 1.6rem;
    }

    .wv-logo {
      max-width: 220px;
      margin: auto;
    }
  }
`

const Header = () => {
  const { state } = useContext(stateContext) as any
  const { queryParams, eventData } = state

  return (
    <HeaderContainer data-testid="mainHeader">
      <div className="wrapper">
        <TitleContainer>
          {eventData ? (
            <h1>Chosen Sponsorship Dashboard for: <strong>{eventData.CoBrandPartnerName}</strong></h1>
          ) : ''}
          <img className="wv-logo" src={logo} alt="World Vision logo" />
        </TitleContainer>
        {queryParams.keyword && eventData && (
          <EventSummary />
        )}
      </div>
    </HeaderContainer>
  )
}

export default Header
