import camelcaseKeys from "camelcase-keys";

const getTotalSponsorships = (orders) => {
  const totalSponsorships = orders.reduce(function (count, currentOrder) {
    count += +currentOrder.numberOfSponsorships;
    return count;
  }, 0);

  return totalSponsorships;
};

function reducer(state, action) {
  switch (action.type) {
    case "updateEventData":
      return { ...state, eventData: action.payload };
    case "addOrders":
      const normalizedOrders = camelcaseKeys(action.payload);
      return {
        ...state,
        orders: normalizedOrders,
        totalSponsorships: getTotalSponsorships(normalizedOrders),
        eventIds: [...new Set(normalizedOrders.map((order) => order.eventId))],
      };
    case "addParams":
      return {
        ...state,
        queryParams: { ...state.queryParams, ...action.payload },
      };
    default:
      throw new Error();
  }
}

export default reducer;
