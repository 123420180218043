import { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { getQueryParam } from "../../helpers/helpers"
import StateContext from '../../helpers/stateContext'
import Spinner from '../Spinner'
import OrderItem from './OrderItem'
import ErrorMessage from '../ErrorMessage'
import WarningMessage from '../WarningMessage'

// Add FileSaver dependency for downloading the file
import { saveAs } from 'file-saver';

const OrderListWrapper = styled(motion.div)`
  display: block;
  background: #f5f5f5;
  padding: 1.6rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2.4rem 2rem;
  transition: all .7s;

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
    grid-gap: 1.6rem 2rem;
  }
`

const Wrapper = styled.div`
  max-width: 1200px;
  margin: auto;

  h2 {
    font-size: 2.4rem;
    line-height: 2.9rem;
    padding: 0 1.6rem 0;
  }

  @media screen and (max-width: 748px) {
    h2{
      font-size: 2rem;
      line-height: 2.4rem;
      margin: 2.4rem 0 .7rem;
    }
  }
`

const MsgWrapper = styled(motion.div)`
  padding: 25px;
  transition: all .7s;
`

const ButtonContainer = styled.div`
  background: #f5f5f5;
  padding: 3.2rem 0 1.6rem;

  @media screen and (max-width: 748px) {
    padding: 1.6rem 0 0;
  }
`

const LoadMore = styled.button`
  font-size: 1.6rem;
  text-transform: uppercase;
  color: #ff6b00;
  line-height: 2rem;
  background: transparent;
  border: 2px solid #ff6b00;
  border-radius: 8px;
  padding: 5px 31px;
  display: block;
  margin: 0 auto;
  transition: background-color 250ms cubic-bezier(0.165, 0.84, 0.44, 1), border-color 250ms cubic-bezier(0.165, 0.84, 0.44, 1), color 250ms cubic-bezier(0.165, 0.84, 0.44, 1), box-shadow 250ms cubic-bezier(0.165, 0.84, 0.44, 1);
  
  &:hover {
    color: #ffffff;
    background-color: #ff6b00;
  }
`

const DownloadButton = styled.button`
  font-size: 1.6rem;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #ff6b00;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 10px auto;
  display: block;
  transition: background-color 250ms ease-in-out;

  &:hover {
    background-color: #ff8b33;
  }
`
//defining order object for typescript
interface Order {
  orderId: string;
  [key: string]: any;  
}

const OrderList = () => {
  const { state, dispatch } = useContext(StateContext) as any;
  const { queryParams } = state
  const [displayedOrders, setDisplayedOrders] = useState([]) as any
  const [displayLoadMoreButton, setDisplayLoadMoreButton] = useState(state.orders.length > 20)
  const [orderRequestCount, setOrderRequestCount] = useState(0)
  const [orderRequestMet, setOrderRequestMet] = useState(false)
  const [fetchInterval, setFetchInterval] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState({
    error: false,
    message: ''
  });

  const [isDownloading, setIsDownloading] = useState(false); 
  const [showDownloadButton, setShowDownloadButton] = useState(false); 

  useEffect(() => {
    const cssc = getQueryParam("cssc") || ''
    const orderType = getQueryParam("order_type") || ''
    const hours = getQueryParam("hours") || 240;
    const keyword = getQueryParam("keyword") || ''
    const goal = getQueryParam("goal") || ''

    const params = {
      keyword,
      cssc,
      hours,
      orderType,
      goal
    }

    dispatch({ type: 'addParams', payload: params })

    const fetchOrders = async () => {
      if (!orderRequestMet) {
        setIsError({
          error: false,
          message: ''
        });
        setIsLoading(true);

        const response = await fetch(
          `${process.env.REACT_APP_WEB_LINK_DONATE}/api/orders/chosen/public?api_key=${process.env.REACT_APP_DONATE_ORDER_KEY}&hours=${hours}&keyword=${keyword}`,
          {
            headers: {
              'x-verification-key': `${process.env.REACT_APP_VERIFICATION_KEY}`,
            }
          })

        if (response.status === 200) {
          let json = await response.json()
          if (json.success && json.data.length >= 1 && json.data[0].order_id) {
            dispatch({ type: 'addOrders', payload: json.data })
          }
        } else {
          console.log('fetch error', response)
          throw new Error(`${response.status}`)
        }

        setOrderRequestCount((prevState: number) => {
          return prevState + 1
        })
        setIsLoading(false)
      }
    };

    // Only call api if keyword in query
    if (keyword) {
      fetchOrders()

      try {
        // Send request for orders every five minutes
        const intervalId = setInterval(async () => {
          await fetchOrders()
        }, 60000 * 5);

        setFetchInterval(Number(intervalId))
      } catch (e) {
        setIsLoading(false);
        setIsError({
          error: true,
          message: 'There was an issue retrieving the data, please get in touch with your contact at World Vision.'
        });
      }
    } else {
      setIsLoading(false)
      setIsError({
        error: true,
        message: 'There was an issue retrieving the data, please get in touch with your contact at World Vision.'
      });
    }
    const report = getQueryParam('report');
    if (report === 'true') {
      setShowDownloadButton(true);
    }

    return () => {
      clearInterval(fetchInterval)
    }
  }, []);

      // New function to handle CSV download
      const downloadCSV = async () => {
        setIsDownloading(true);
        try {
          const response = await fetch(
            `${process.env.REACT_APP_WEB_LINK_DONATE}/api/orders/chosen/public?api_key=${process.env.REACT_APP_DONATE_ORDER_KEY}&hours=${queryParams.hours}&keyword=${queryParams.keyword}`,
            {
              headers: {
                'x-verification-key': `${process.env.REACT_APP_VERIFICATION_KEY}`,
              }
            });
    
          if (response.status === 200) {
            const data = await response.json();
            if (data.success && data.data.length > 0) {
              const csvContent = convertToCSV(data.data);
              const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
              saveAs(blob, 'order_data.csv');
            }
          }
        } catch (error) {
          console.error("Error downloading CSV:", error);
        }
        setIsDownloading(false);
      };

      // New function to convert data to CSV; TY ChatGPT
      const convertToCSV = (data: Order[]): string => {
        const headers = Object.keys(data[0]).join(",");  // Get the headers from the first object
        const rows = data.map(order => Object.values(order).join(",")).join("\n");  // Convert each order to a CSV row
        return `${headers}\n${rows}`;  // Return the CSV string with headers and rows
      };
  useEffect(() => {
    // clear timeout to stop calls if cap met
    if (orderRequestCount >= 20) {
      setOrderRequestMet(true)
      clearInterval(fetchInterval)
    }
  }, [orderRequestCount])

  useEffect(() => {
    // Compare len of displayed orders to state orders
    // If greater set flag to display button
    if (displayedOrders.length === 0 && state.orders.length >= 1) {
      setDisplayedOrders(state.orders <= 20 ? state.orders.length : state.orders.slice(0, 20))
    }

    if (state.orders.length > displayedOrders.length) {
      setDisplayLoadMoreButton(true)
    }
    else {
      setDisplayLoadMoreButton(false)
    }

  }, [state.orders, displayedOrders])

  const getMoreOrders = () => {
    const ordersKey = displayedOrders.length

    const newOrders = state.orders.slice(ordersKey, ordersKey + 20)

    setDisplayedOrders((prevState: any) => {
      return [...prevState, ...newOrders]
    })

    if (newOrders.length < 20 || displayedOrders.length === state.orders.length) {
      // Set the flag to remove button
      setDisplayLoadMoreButton(false)
    }
  }

  return (<>
    {isError.error && (
      <MsgWrapper
        data-testid="orderListError"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: .3 }}>
        <ErrorMessage message={isError.message} />
      </MsgWrapper>
    )}

    {orderRequestMet && (
      <MsgWrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: .3 }}>
        <WarningMessage message="Please refresh the page for the latest order data." />
      </MsgWrapper>
    )}

    {isLoading && (<Spinner inProp={isLoading} />)}

    <AnimatePresence>
      {queryParams.keyword && displayedOrders?.length && (
        <Wrapper>
          <h2>Chosen Sign-ups</h2>
          <OrderListWrapper
            key="orderlist"
            data-testid="orderList"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: .5 }}>
            {displayedOrders && displayedOrders.map((order: any, index: Number) => <OrderItem key={`${order.orderId}${index}`} animate={{ opacity: 1 }}
              exit={{ opacity: 0 }} orderData={order} />)}
          </OrderListWrapper>
        </Wrapper>
      )}
    </AnimatePresence>

    {displayLoadMoreButton && (
      <ButtonContainer className="buttonContainer">
        <LoadMore type="button" onClick={() => getMoreOrders()}>Load More</LoadMore>
      </ButtonContainer>
    )}

{showDownloadButton && ( // Conditionally render the button if `report=true`
      <ButtonContainer>
        <DownloadButton type="button" onClick={downloadCSV} disabled={isDownloading}>
          {isDownloading ? 'Downloading...' : 'Download Photo Consent Report'}
        </DownloadButton>
      </ButtonContainer>
    )}
  </>)
}


export default OrderList
