import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

const ItemWrapper = styled(motion.div)`
  display: flex;
  background: #fff;
  border-radius: 8px;
  padding: 1.2rem;
  box-shadow: rgba(204, 204, 204, 0.5) 0px 3px 5px 0px;
`

const ImgContainer = styled.div`

  img {
    width: 100%;
    margin: 0 2rem 0 0;
    width: 93px;
    height: 93px;
    object-fit: cover;
    border-radius: 8px;
  }
`

const InfoContainer = styled.div`

display: flex;
flex-direction: column;
justify-content: center;

  p {
    margin: 0;
  }
`

const OrderItem = (props: any) => {
  const { numberOfSponsorships, donorFirstName, donorLastInitial, orderCreateDate, donorResources, donorPhotoConsent } = props.orderData
  const formattedOrderDate = new Date(orderCreateDate.replace(' ', 'T')).toLocaleDateString() 
  const donorName = donorPhotoConsent === "true" ? `${donorFirstName} ${donorLastInitial.toUpperCase()}.` : "Someone";
  const infoStr = `${donorName} signed up to be chosen by ${numberOfSponsorships} child${numberOfSponsorships > 1 ? 'ren' : ''}!`
  //without donor consent, display default wvus image
  const donorImage = donorPhotoConsent === "true" && donorResources ? donorResources : 'https://wvusstatic.com/images/default-image.jpg';

  return (
    <AnimatePresence>
      <ItemWrapper
        data-testid="orderItem"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}>
        <ImgContainer>
        <img src={donorImage}/>
        </ImgContainer>

        <InfoContainer>
          <p>{infoStr}</p>
          <p><strong>{formattedOrderDate}</strong></p>
        </InfoContainer>
      </ItemWrapper>
    </AnimatePresence>
  )
}

export default OrderItem
