interface WarningMessageProps {
  message: String
}

const WarningMessage = (props: WarningMessageProps) => {
  const { message } = props

  return (
    <div className="alert alert-warning" role="alert">
      <i className="fa fa-times-circle" aria-hidden="true"></i> {message}
    </div>
  )
}

export default WarningMessage
