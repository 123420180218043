interface ErrorMessageProps {
  message: String
}

const ErrorMessage = (props: ErrorMessageProps) => {
  const { message } = props

  return (
    <div className="alert alert-danger" role="alert">
      <i className="fa fa-times-circle" aria-hidden="true"></i> {message}
    </div>
  )
}

export default ErrorMessage
