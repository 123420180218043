interface GoalTextProps {
    isGoalMet: boolean
}

const GoalText = (props: GoalTextProps) => {

    if (props.isGoalMet) {
        return (<h2><strong> Well done!</strong > God-sized goal met!</h2>)
    }

    else {
        return (<h2>Total Chosen Sponsorships:</h2>)
    }

}

export default GoalText