export function addUrlParams(url, params) {
  const { host, protocol } = window.location;
  const isLocalPath = (path) => /^\/.*/.test(path);
  const urlPath = isLocalPath(url) ? protocol + host + url : url;
  const urlObject = new URL(urlPath);
  const paramsObject = new URLSearchParams(urlObject.searchParams);
  const paramKeys = Object.keys(params);

  for (const key of paramKeys) {
    if (paramsObject.get(key) === null) {
      paramsObject.append(key, params[key]);
    } else {
      paramsObject.set(key, params[key]);
    }
  }

  urlObject.search = paramsObject.toString();

  return urlObject.href;
}

export function parseJson(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
}

export function getQueryParam(key, caseSensitive = false) {
  const paramKey = (typeof key === "string" && key.toLowerCase()) || "";
  const pageUrl = caseSensitive
    ? window.location.href
    : window.location.href.toLowerCase();

  const urlObject = new URL(pageUrl);
  const query = urlObject.searchParams;

  return query.get(paramKey);
}

function entriesToObject(coll) {
  const newObj = {};
  for (const [key, val] of coll) {
    newObj[key] = val;
  }

  return newObj;
}

export function getUrlPathSegments(url) {
  const urlObj = new URL(url.replace(/\/(\/)+/g, "/"));
  const [, ...segments] = urlObj.pathname.replace(/(\/)$/, "").split("/");
  return segments;
}

export function parseAppUrl(url) {
  const segments = getUrlPathSegments(url);
  const urlObj = new URL(url);
  const params = entriesToObject(new URLSearchParams(urlObj.search).entries());

  return { url, params, segments };
}

export function keysToLowerFirstChar(obj) {
  return Object.keys(obj).reduce((coll, key) => {
    const newKey = key.charAt(0).toLowerCase() + key.substring(1);
    coll[newKey] = obj[key];
    return coll;
  }, {});
}

export function keysToUpperFirstChar(obj) {
  return Object.keys(obj).reduce((coll, key) => {
    const newKey = key.charAt(0).toUpperCase() + key.substring(1);
    coll[newKey] = obj[key];
    return coll;
  }, {});
}