import { useReducer, useMemo } from 'react'
import Header from './components/Header';
import Footer from './components/Footer';
import OrderList from './components/orders/OrderList'
import StateContext from './helpers/stateContext';
import reducer from './helpers/stateReducer';

const initialState: object = {
  orders: [],
  eventData: {},
  eventIds: [],
  queryParams: {
    keyword: '',
    cssc: '',
    hours: 240,
    orderType: 'chosen',
    goal: 0
  },
  totalSponsorships: 0
};

const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const stateContextMemo = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <StateContext.Provider value={stateContextMemo}>
      <Header />
      <OrderList />
      <Footer />
    </StateContext.Provider>
  );
}

export default App;
