
const ideaBaseUrl = process.env.REACT_APP_IDEA_BASE_URL;
const ideaApiKey = process.env.REACT_APP_IDEA_SUB_KEY;

export async function getEventByKeyword(keyword) {
  try {
    const response = await fetch(
      `${ideaBaseUrl}/events/${keyword}?idType=keyword&application=pastor.dashboard`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": ideaApiKey
        }
      }
    );

    const jsonData = await response.json();

    if (!response.ok || !jsonData.EventNumber) {
      const errorMessage = jsonData.message || "Invalid service response";
      throw new Error(errorMessage);
    }

    return jsonData;
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

