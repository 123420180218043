import styled from 'styled-components'
import { motion } from 'framer-motion'

const EventInfoWrapper = styled(motion.div)`


  p {
    font-size: 2rem;
    line-height: 2.4rem;
    margin-bottom: 1.3rem;

    :last-of-type {
      margin-bottom: 0;
    }
  }
`

interface EventInfoProps {
  country: string,
  signupEndDate: string,
  revealDate: any
}

const EventInfo = (props: EventInfoProps) => {
  const { country, signupEndDate, revealDate } = props

  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const signupEndDateTimeStamp = Date.parse(signupEndDate)
  var signupDateOptions = {};
  signupDateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: localTimezone, timeZoneName: 'short' }
  const formattedSignupEndDate = new Date(signupEndDateTimeStamp).toLocaleDateString('en-US', signupDateOptions)

  const revealDateTimeStamp = Date.parse(revealDate)
  var revealDateOptions = {};
  revealDateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: localTimezone, timeZoneName: 'short' }
  const formattedRevealDate = new Date(revealDateTimeStamp).toLocaleDateString('en-US', revealDateOptions)

  return (
    <EventInfoWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
      <p>Country: <strong>{country}</strong></p>
      <p>Sign-up by: <strong>{formattedSignupEndDate}</strong></p>
      <p>Reveal Day: <strong>{formattedRevealDate}</strong></p>
    </EventInfoWrapper>
  )
}

export default EventInfo
