import { useEffect, useState } from 'react'
import styled from 'styled-components'

const ProgressContainer = styled.div`
   .count-wrapper {
      display: flex;
      justify-content: space-between;
    }

    .spon-count {
      white-space: nowrap;
      display: flex;
    }
`

const BarWrapper = styled.div`
  background-color: #d8d8d8;
  border-radius: 10px;
  display: flex;
`

const Filler = styled.div`
  background-color: #ff6b00;
  height: 10px;
  border-radius: 10px;
  width: 0;
`

const Exceed = styled.div`
  background-color: #00ACCA;
  height: 10px;
  border-radius: 0 10px 10px 0;
  width: 0;
`

interface ProgCountProps {
  totalSponsorships: number,
  goal: any
}

const ProgressBar = (props: ProgCountProps) => {
  const [percentRange, setProgress] = useState(props.totalSponsorships);
  const [percentOver, setExcess] = useState(0);
  const [borderRadius, setBorder] = useState('10px');
  const [sponText, setSponText] = useState((props.totalSponsorships) + ' Chosen Sponsorships');
  const [goalText, setGoalText] = useState((props.goal) + ' goal');
  const [goalWidth, setGoalWidth] = useState(0);
  const [align, setAlign] = useState('flex-start');

  useEffect(() => {
    setProgress((props.totalSponsorships / parseFloat(props.goal) * 100));
    setSponText((props.totalSponsorships) + ' Chosen Sponsorships');

    if (props.totalSponsorships > parseInt(props.goal)) {
      setProgress((parseFloat(props.goal) / props.totalSponsorships) * 100);
      setExcess(((props.totalSponsorships - parseFloat(props.goal)) / props.totalSponsorships) * 100);
      setBorder('10px 0 0 10px');
      setSponText((props.goal) + ' goal');
      setGoalText((props.totalSponsorships) + '');
      setGoalWidth((parseFloat(props.goal) / props.totalSponsorships) * 100);
      setAlign('center');
    }

  }, [props]);

  return (
    <ProgressContainer>
      <BarWrapper>
        <Filler id='filler' style={{ width: `${percentRange}%`, borderRadius: `${borderRadius}` }} /><Exceed style={{ width: `${percentOver}%` }} />
      </BarWrapper>
      <div className="count-wrapper">
        <p className="spon-count" style={{ width: `${goalWidth}%`, justifyContent: `${align}` }}> {sponText}</p>
        <p className="spon-count"> {goalText}</p>
      </div>
    </ProgressContainer >
  );
};

export default ProgressBar;
