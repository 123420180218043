import { useState, useEffect } from 'react'
import styled from 'styled-components'
import Confetti from 'react-dom-confetti'
import ProgressBar from './ProgressBar'

const confettiConfig = {
  angle: 0,
  spread: 360,
  startVelocity: 18,
  elementCount: 120,
  dragFriction: 0.11,
  duration: 1500,
  stagger: 0,
  width: "9px",
  height: "9px",
  perspective: "500px",
  colors: [
    "#ff6b00",
    "#00acca",
    "#46bb95",
    "#fdd25f",
    "#e54f5e",
    "#9054a1",
    "#e54f5e"
  ]
}

const ConfettiWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 75px;
`
const SponCountWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  button {
    max-width: 300px;
    margin: auto;
  }
`

interface SponCountProps {
  totalSponsorships: number,
  goal: string
}

const SponsorshipCount = (props: SponCountProps) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    const animationDiv = document.getElementById('animationDiv');
    const isInViewport = (element: any) => {
      const rect = element.getBoundingClientRect();
      if (rect.bottom < 0 || rect.right < 0 || rect.left > window.innerWidth || rect.top > window.innerHeight) {
        return false;
      }
      else {
        return true;
      }
    }

    if (props.totalSponsorships >= parseInt(props.goal)) {
      if (isInViewport(animationDiv) === true) {
        setActive(true);
      }
      else {
        animationDiv?.scrollIntoView();
        setActive(true);
      }
    }
  }, [props.totalSponsorships]);

  return (
    <SponCountWrapper>
      <ConfettiWrapper>
        <Confetti
          config={confettiConfig}
          active={active}
        />
      </ConfettiWrapper>
      <ProgressBar totalSponsorships={props.totalSponsorships} goal={props.goal} />
    </SponCountWrapper>
  )
}

export default SponsorshipCount
